<div id="loader" *ngIf="isLoading">
    <div class="loader-cubes">
       <!-- <div class="loader-cube1 loader-cube"></div>
        <div class="loader-cube2 loader-cube"></div>

        <div class="loader-cube4 loader-cube"></div>
        <div class="loader-cube3 loader-cube"></div>  -->
        <img src="../../assets/img/pan/pan.ico">

    </div>
<!-- 
    <div class="row">
        <div id="loader">
            <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div> 
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="loading"></div>
        </div>
      </div> -->

</div>